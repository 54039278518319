<template>
  <b-card-code
    no-body
    title="Static page"
  >
    <div class="dt_adv_search ml-1 mr-1">
      <div class="row">
        <div class="col-md-12">
          <div class="form-row mb-1 justify-content-between">
            <form-image-page
              :is-loading="isLoading"
              :edit-id="editId"
              :validations="validations"
              :create-item="createItem"
              :update-item="updateItem"
              :clean-up-form="cleanUpForm"
              :form-payload="formPayload"
              :parent="parent"
              :static-image-or-page="'Static Page'"
              @payload="getPayload($event)"
            />
            <!-- <div class="col-lg-4 mb-lg-0 align-self-end">
              <b-form-select
                v-model="filter.category"
                :options="optionCategory"
              />
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <table-image-page
      :result="result"
      :is-loading="isLoading"
      :get-category-data="getData"
      :edit-item="editItem"
      :delete-item="deleteItem"
      :static-image-or-page="'Static Page'"
      :is-static-page="true"
    />
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import {
  BFormSelect,
} from 'bootstrap-vue'

import FormImagePage from '@/components/ImagePage/FormImagePage.vue'
import TableImagePage from '@/components/ImagePage/TableImagePage.vue'

Vue.use(VuejsDialog)

export default {
  title() {
    return 'Static page'
  },
  components: {
    BFormSelect,
    BCardCode,
    TableImagePage,
    FormImagePage,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      result: {},
      isLoading: false,
      validations: {},
      formPayload: Object,
      editId: null,
      categoryName: '',
      parent: {},
      filter: {
        type: 'page',
      },
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getPayload(value) {
      if (value) {
        this.formPayload = value
      }
    },
    cleanUpForm() {
      this.editId = null
      this.formPayload = {
        slug: '',
        type: '',
        title: '',
        content_text: '',
        content_file: '',
      }
      this.validations = ''
      this.parent = {}
    },
    createItem() {
      const form = this.preparePayload()
      this.isLoading = true

      form.append('type', 'page')
      if (this.formPayload.slug) {
        this.$http.post('/admin/page-image', form, {
          headers: { 'content-type': 'multipart/form-data' },
        })
          .then(response => {
            this.$bvModal.hide('form-image-page-modal')
            this.getData()
            successNotification(this, 'Success', 'Static page successfully created')
            this.cleanUpForm()
            this.isLoading = false
          })
          .catch(error => {
            if (error.response.data.meta.validations) {
              this.validations = error.response.data.meta.validations
            }
            this.isLoading = false
          })
      } else {
        this.$swal({
          text: 'Slug Wajib di isi.',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        this.isLoading = false
      }
    },
    updateItem() {
      const form = this.preparePayload()
      this.isLoading = true
      form.append('_method', 'PATCH')

      this.$http.post(`/admin/page-image/${this.editId}`, form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.$bvModal.hide('form-image-page-modal')
          this.getData()
          successNotification(this, 'Success', 'Static page successfully updated!')
          this.cleanUpForm()
          this.isLoading = false
        })
        .catch(error => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
          this.isLoading = false
        })
    },
    preparePayload() {
      const form = new FormData()
      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }

      return form
    },
    editItem(item) {
      this.cleanUpForm()
      this.editId = `${item.id}`

      this.formPayload = {
        slug: item.slug,
        type: item.type,
        title: item.title,
        content_text: item.content_text,
      }
      this.$bvModal.show('form-image-page-modal')
    },
    deleteItem(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this static page?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`admin/page-image/${id}`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Static page successfully deleted',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    getData(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/page-image', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">

</style>
